<template>
  <div>
    <div v-if="data !== null && data.text !== undefined">
      <v-snackbar
        key="0"
        v-model="show"
        :color="data.color || 'default'"
        :timeout="5000"
        left
        :multi-line="60 < data.text.length"
        :bottom="true"
      >
        {{ data.text.toUpperCase() }}

        <template v-slot:action="{ attrs }">
          <v-btn small icon v-bind="attrs" @click="show = false" class="ml-2"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {},
  data() {
    return {
      show: true,
    };
  },
  computed: {
    ...mapGetters({
      data: "messages/data",
    }),
  },
  watch: {
    data: function () {
      this.show = true;
    },
  },
  methods: {},
};
</script>

<style></style>
